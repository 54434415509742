body {
  font-family: "Pretendard";
}

@font-face {
  font-family: "Pretendard";
  src: url("./common/fonts/Pretendard-Medium.woff");
  font-weight: 500;
  font-display: swap;
}
